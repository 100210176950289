import React from 'react';
import { ArrowLeftIcon } from '../../../../components/icons';
import { HomeTitle } from '../../../../components/typography';
import { NavLinksEnum } from '../../../../store/ui/ui.types';
import HomePresentationSubTitle from '../HomePresentationSubTitle';
import HomePresentationDecoration from '../HomePresentationDecoration';
import * as S from './HomePresentation.css';

type Props = {
  selectedUrl: NavLinksEnum,
};

const TITLES = {
  defalt:
    'O IEPS Data facilita o acesso a dados e indicadores de saúde de todo Brasil.',
  [NavLinksEnum.VISUALIZATIONS]: 'Explore indicadores de saúde de todo Brasil.',
  [NavLinksEnum.ABOUT]:
    'Conheça o Instituto de Estudos para Políticas de Saúde.',
  [NavLinksEnum.DATA_DOWNLOAD]:
    'Acesse e baixe os dados completos do IEPS Data.',
  [NavLinksEnum.LOCAL_HEATH_PANORAMA]:
    'Acesse um panorama dos indicadores de saúde por município e região de saúde.',
  [NavLinksEnum.METHOD_DOCUMENTATION]:
    'Entenda a fonte e a metodologia dos indicadores de saúde do IEPS Data.',
  [NavLinksEnum.PROJECTS_GALLERY]:
    'Conheça outros projetos de visualização de dados de saúde.',
};

const HomePresentation = ({ selectedUrl }: Props) => {
  const renderHomeKnowMore = () => {
    if (selectedUrl !== NavLinksEnum.HOME) {
      return null;
    }

    return (
      <S.TextBlock>
        <S.Arrow>
          <ArrowLeftIcon />
        </S.Arrow>
        <S.TextCta>
          Explore o portal para conhecer em mais detalhes e comparar indicadores
          de saúde de municípios, regiões de saúde e estados.
        </S.TextCta>
      </S.TextBlock>
    );
  };

  return (
    <S.HomePresentation>
      <HomeTitle>{TITLES[selectedUrl] || TITLES.defalt}</HomeTitle>
      {renderHomeKnowMore()}
      <HomePresentationSubTitle selectedUrl={selectedUrl} />
      <HomePresentationDecoration selectedUrl={selectedUrl} />
    </S.HomePresentation>
  );
};

export default HomePresentation;
