import React from 'react';

const PanoramaDecoration = () => (
  <svg
    width="495"
    height="441"
    viewBox="0 0 495 441"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M494.002 247.002C494.002 236.771 485.232 228.002 475.002 228.002C464.771 228.002 456.001 236.771 456.001 247.002C456.001 257.233 464.771 266.002 475.002 266.002C486.694 266.002 494.002 257.233 494.002 247.002Z"
      fill="#D97A08"
    />
    <path
      d="M422.389 422.388C429.697 415.08 429.697 403.388 422.389 396.08C415.082 388.772 403.389 388.772 396.082 396.08C388.774 403.388 388.774 415.08 396.082 422.388C403.389 429.695 415.082 429.695 422.389 422.388Z"
      fill="#D97A08"
    />
    <path
      d="M247.004 0C236.773 0 228.004 8.76928 228.004 19.0001C228.004 29.2309 236.773 38.0002 247.004 38.0002C257.235 38.0002 266.004 29.2309 266.004 19.0001C266.004 8.76928 257.235 0 247.004 0Z"
      fill="#D97A08"
    />
    <path
      d="M73.0765 73.0767C65.7688 78.9229 65.7688 92.0768 73.0765 97.923C80.3842 105.231 92.0766 105.231 99.3843 97.923C106.692 90.6153 106.692 78.9229 99.3843 71.6152C92.0766 65.769 78.9227 65.769 73.0765 73.0767Z"
      fill="#D97A08"
    />
    <path
      d="M0 247.002C0 257.233 8.76928 266.002 19.0001 266.002C29.2309 266.002 38.0002 257.233 38.0002 247.002C38.0002 236.771 29.2309 228.002 19.0001 228.002C8.76928 228.002 0 236.771 0 247.002Z"
      fill="#D97A08"
    />
    <path
      d="M73.0765 422.388C80.3842 429.695 92.0766 429.695 99.3843 422.388C106.692 415.08 106.692 403.388 99.3843 396.08C92.0766 388.772 80.3842 388.772 73.0765 396.08C65.7688 403.388 65.7688 415.08 73.0765 422.388Z"
      fill="#D97A08"
    />
    <path
      d="M422.389 73.076C415.082 65.7683 403.389 65.7683 396.082 73.076C388.774 80.3837 388.774 92.0761 396.082 99.3839C403.389 106.692 415.082 106.692 422.389 99.3839C429.697 92.0761 429.697 78.9222 422.389 73.076Z"
      fill="#D97A08"
    />
    <path
      d="M207.281 371.121C207.281 375.131 225.327 378.568 246.379 378.568C267.432 378.568 285.477 375.131 285.477 371.121C285.477 367.111 267.432 363.673 246.379 363.673C225.327 363.673 207.281 367.111 207.281 371.121Z"
      fill="#D97A08"
      fillOpacity="0.4"
    />
    <rect
      x="93.5908"
      y="60.0776"
      width="15.1021"
      height="15.1021"
      fill="#F8951E"
      stroke="#7C4504"
    />
    <rect
      x="55.1133"
      y="141.998"
      width="66.0251"
      height="6.44724"
      fill="#F8951E"
      stroke="#7C4504"
    />
    <rect
      x="73.7312"
      y="154.409"
      width="47.407"
      height="6.44724"
      fill="#F8951E"
      stroke="#7C4504"
    />
    <rect
      x="97.9346"
      y="166.821"
      width="23.2035"
      height="6.44724"
      fill="#F8951E"
      stroke="#7C4504"
    />
    <rect
      x="443.852"
      y="324.696"
      width="66.0251"
      height="6.44724"
      transform="rotate(180 443.852 324.696)"
      fill="#F8951E"
      stroke="#7C4504"
    />
    <rect
      x="425.234"
      y="312.284"
      width="47.407"
      height="6.44724"
      transform="rotate(180 425.234 312.284)"
      fill="#F8951E"
      stroke="#7C4504"
    />
    <rect
      x="401.03"
      y="299.872"
      width="23.2035"
      height="6.44724"
      transform="rotate(180 401.03 299.872)"
      fill="#F8951E"
      stroke="#7C4504"
    />
    <rect
      x="31.5305"
      y="240.053"
      width="15.1021"
      height="15.1021"
      fill="#F8951E"
      stroke="#7C4504"
    />
    <rect
      x="129.586"
      y="141.998"
      width="18.8593"
      height="18.8593"
      fill="#F8951E"
      stroke="#7C4504"
    />
    <rect
      x="369.38"
      y="312.284"
      width="18.8593"
      height="18.8593"
      transform="rotate(180 369.38 312.284)"
      fill="#F8951E"
      stroke="#7C4504"
    />
    <rect
      x="397.686"
      y="384.033"
      width="15.1021"
      height="15.1021"
      fill="#F8951E"
      stroke="#7C4504"
    />
    <rect
      x="468.435"
      y="240.053"
      width="15.1021"
      height="15.1021"
      fill="#F8951E"
      stroke="#7C4504"
    />
    <rect
      x="402.651"
      y="60.0776"
      width="15.1021"
      height="15.1021"
      fill="#F8951E"
      stroke="#7C4504"
    />
    <rect
      x="240.053"
      y="11.6704"
      width="15.1021"
      height="15.1021"
      fill="#F8951E"
      stroke="#7C4504"
    />
    <path
      d="M346.295 222.883C346.295 306.831 247.346 371.027 247.346 371.027C247.346 371.027 148.396 306.831 148.396 222.883C148.396 168.564 192.923 124.121 247.346 124.121C301.768 124.121 346.295 168.564 346.295 222.883Z"
      fill="url(#paint0_linear_1323_18381)"
      stroke="#7C4504"
      strokeMiterlimit="10"
    />
    <path
      d="M247.345 247.573C266.472 247.573 281.978 232.097 281.978 213.006C281.978 193.916 266.472 178.439 247.345 178.439C228.218 178.439 212.713 193.916 212.713 213.006C212.713 232.097 228.218 247.573 247.345 247.573Z"
      fill="#F8951E"
      stroke="#7C4504"
      strokeMiterlimit="10"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1323_18381"
        x1="247.346"
        y1="124.121"
        x2="247.346"
        y2="371.027"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D97A08" />
        <stop offset="1" stopColor="#D97A08" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default PanoramaDecoration;
