import styled from 'styled-components';

export const HomePresentation = styled.div`
  margin-top: 2rem;
  margin-left: 11.5rem;
`;
export const TextBlock = styled.div`
  display: flex;
`;

export const TextCta = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 2.2rem;
  line-height: 2.5rem;
`;

export const Text = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 2.2rem;
  line-height: 2.5rem;
  max-width: 35rem;
`;

export const Arrow = styled.div`
  display: block;
  margin: 6px;
`;
