import styled from 'styled-components';

export const Home = styled.div``;

export const Main = styled.main`
  display: flex;
`;

export const Aside = styled.aside``;

export const Content = styled.div`
  position: relative;
`;
