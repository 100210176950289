import React from 'react';

const VisualizationDecoration = () => (
  <svg
    width="892"
    height="319"
    viewBox="0 0 892 319"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g style={{ mixBlendMode: 'multiply' }}>
      <rect
        x="0.00012207"
        y="210.368"
        width="38.5408"
        height="41.7525"
        rx="19.2704"
        fill="url(#paint0_linear_1276_11929)"
      />
      <rect
        x="94.7458"
        y="210.368"
        width="38.5408"
        height="162.192"
        rx="19.2704"
        fill="url(#paint1_linear_1276_11929)"
      />
      <rect
        x="0.142944"
        y="210.394"
        width="38.39"
        height="213.887"
        rx="19.195"
        fill="url(#paint2_linear_1276_11929)"
      />
      <rect
        x="189.493"
        y="152.558"
        width="38.5408"
        height="321.173"
        rx="19.2704"
        fill="url(#paint3_linear_1276_11929)"
      />
      <rect
        x="284.238"
        y="136.498"
        width="38.5408"
        height="261.756"
        rx="19.2704"
        fill="url(#paint4_linear_1276_11929)"
      />
      <rect
        x="378.984"
        y="112.411"
        width="38.5408"
        height="268.18"
        rx="19.2704"
        fill="url(#paint5_linear_1276_11929)"
      />
      <rect
        x="473.731"
        y="104.382"
        width="38.5408"
        height="268.18"
        rx="19.2704"
        fill="url(#paint6_linear_1276_11929)"
      />
      <rect
        x="568.477"
        y="49.7815"
        width="38.5408"
        height="420.737"
        rx="19.2704"
        fill="url(#paint7_linear_1276_11929)"
      />
      <rect
        x="663.223"
        y="9.63525"
        width="38.5408"
        height="391.831"
        rx="19.2704"
        fill="url(#paint8_linear_1276_11929)"
      />
      <rect
        x="757.969"
        width="38.5408"
        height="448.037"
        rx="19.2704"
        fill="url(#paint9_linear_1276_11929)"
      />
      <rect
        x="852.715"
        y="62.6292"
        width="38.5408"
        height="279.421"
        rx="19.2704"
        fill="url(#paint10_linear_1276_11929)"
      />
    </g>
    <path
      d="M19.271 167.812L114.017 139.71L208.763 158.98L303.509 131.68L398.255 104.381L493.002 65.8397L587.748 72.2632L682.494 81.0954L777.24 50.584L871.986 75.4749"
      stroke="#11312C"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.271 231.245L114.017 232.85L208.763 168.616L303.509 155.769L398.255 131.681L493.002 123.652L587.748 68.2491L682.494 28.9054L777.24 22.4819L872.789 90.7313"
      stroke="#11312C"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.271 409.495L114.017 291.464L208.763 212.776L303.509 194.309L398.255 170.221L493.002 165.403L587.748 104.38L682.494 56.2045L777.24 50.584L871.986 128.468"
      stroke="#11312C"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="13.2676"
      y="161.087"
      width="11.8469"
      height="11.8469"
      fill="#2C7C70"
      stroke="#11312C"
    />
    <rect
      x="13.2676"
      y="225.322"
      width="11.8469"
      height="11.8469"
      fill="#2C7C70"
      stroke="#11312C"
    />
    <rect
      x="108.093"
      y="133.787"
      width="11.8469"
      height="11.8469"
      fill="#2C7C70"
      stroke="#11312C"
    />
    <rect
      x="108.093"
      y="284.739"
      width="11.8469"
      height="11.8469"
      fill="#2C7C70"
      stroke="#11312C"
    />
    <rect
      x="108.093"
      y="226.927"
      width="11.8469"
      height="11.8469"
      fill="#2C7C70"
      stroke="#11312C"
    />
    <rect
      x="202.839"
      y="153.057"
      width="11.8469"
      height="11.8469"
      fill="#2C7C70"
      stroke="#11312C"
    />
    <rect
      x="202.839"
      y="207.656"
      width="11.8469"
      height="11.8469"
      fill="#2C7C70"
      stroke="#11312C"
    />
    <rect
      x="202.839"
      y="162.692"
      width="11.8469"
      height="11.8469"
      fill="#2C7C70"
      stroke="#11312C"
    />
    <rect
      x="297.586"
      y="125.758"
      width="11.8469"
      height="11.8469"
      fill="#2C7C70"
      stroke="#11312C"
    />
    <rect
      x="297.586"
      y="188.387"
      width="11.8469"
      height="11.8469"
      fill="#2C7C70"
      stroke="#11312C"
    />
    <rect
      x="297.586"
      y="149.846"
      width="11.8469"
      height="11.8469"
      fill="#2C7C70"
      stroke="#11312C"
    />
    <rect
      x="392.332"
      y="98.4585"
      width="11.8469"
      height="11.8469"
      fill="#2C7C70"
      stroke="#11312C"
    />
    <rect
      x="392.332"
      y="164.299"
      width="11.8469"
      height="11.8469"
      fill="#2C7C70"
      stroke="#11312C"
    />
    <rect
      x="392.332"
      y="125.758"
      width="11.8469"
      height="11.8469"
      fill="#2C7C70"
      stroke="#11312C"
    />
    <rect
      x="487.077"
      y="59.9167"
      width="11.8469"
      height="11.8469"
      fill="#2C7C70"
      stroke="#11312C"
    />
    <rect
      x="487.077"
      y="159.48"
      width="11.8469"
      height="11.8469"
      fill="#2C7C70"
      stroke="#11312C"
    />
    <rect
      x="487.077"
      y="117.728"
      width="11.8469"
      height="11.8469"
      fill="#2C7C70"
      stroke="#11312C"
    />
    <rect
      x="581.823"
      y="66.3403"
      width="11.8469"
      height="11.8469"
      fill="#2C7C70"
      stroke="#11312C"
    />
    <rect
      x="581.823"
      y="98.4575"
      width="11.8469"
      height="11.8469"
      fill="#2C7C70"
      stroke="#11312C"
    />
    <rect
      x="581.823"
      y="63.1289"
      width="11.8469"
      height="11.8469"
      fill="#2C7C70"
      stroke="#11312C"
    />
    <rect
      x="866.062"
      y="69.552"
      width="11.8469"
      height="11.8469"
      fill="#2C7C70"
      stroke="#11312C"
    />
    <rect
      x="866.062"
      y="122.545"
      width="11.8469"
      height="11.8469"
      fill="#2C7C70"
      stroke="#11312C"
    />
    <rect
      x="866.062"
      y="84.0044"
      width="11.8469"
      height="11.8469"
      fill="#2C7C70"
      stroke="#11312C"
    />
    <rect
      x="676.569"
      y="74.3691"
      width="11.8469"
      height="11.8469"
      fill="#2C7C70"
      stroke="#11312C"
    />
    <rect
      x="676.569"
      y="50.2815"
      width="11.8469"
      height="11.8469"
      fill="#2C7C70"
      stroke="#11312C"
    />
    <rect
      x="676.569"
      y="22.9817"
      width="11.8469"
      height="11.8469"
      fill="#2C7C70"
      stroke="#11312C"
    />
    <rect
      x="771.316"
      y="43.8589"
      width="11.8469"
      height="11.8469"
      fill="#2C7C70"
      stroke="#11312C"
    />
    <rect
      x="771.316"
      y="47.0703"
      width="11.8469"
      height="11.8469"
      fill="#2C7C70"
      stroke="#11312C"
    />
    <rect
      x="771.316"
      y="16.5588"
      width="11.8469"
      height="11.8469"
      fill="#2C7C70"
      stroke="#11312C"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1276_11929"
        x1="19.2705"
        y1="210.368"
        x2="19.2705"
        y2="252.121"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E9ECEF" />
        <stop offset="1" stopColor="#D9EBE8" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1276_11929"
        x1="114.016"
        y1="210.368"
        x2="114.016"
        y2="372.561"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E9ECEF" />
        <stop offset="1" stopColor="#BDBDBD" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1276_11929"
        x1="19.3379"
        y1="210.394"
        x2="19.3379"
        y2="424.281"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E9ECEF" />
        <stop offset="1" stopColor="#BDBDBD" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1276_11929"
        x1="208.763"
        y1="152.558"
        x2="208.763"
        y2="473.731"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E9ECEF" />
        <stop offset="1" stopColor="#BDBDBD" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1276_11929"
        x1="303.509"
        y1="136.498"
        x2="303.509"
        y2="398.255"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E9ECEF" />
        <stop offset="1" stopColor="#BDBDBD" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_1276_11929"
        x1="398.254"
        y1="112.411"
        x2="398.254"
        y2="380.591"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E9ECEF" />
        <stop offset="1" stopColor="#BDBDBD" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_1276_11929"
        x1="493.001"
        y1="104.382"
        x2="493.001"
        y2="372.562"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E9ECEF" />
        <stop offset="1" stopColor="#BDBDBD" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_1276_11929"
        x1="587.747"
        y1="49.7815"
        x2="587.747"
        y2="470.518"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E9ECEF" />
        <stop offset="1" stopColor="#BDBDBD" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_1276_11929"
        x1="682.494"
        y1="9.63525"
        x2="682.494"
        y2="401.467"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E9ECEF" />
        <stop offset="1" stopColor="#BDBDBD" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_1276_11929"
        x1="777.239"
        y1="0"
        x2="777.239"
        y2="448.037"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E9ECEF" />
        <stop offset="1" stopColor="#BDBDBD" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_1276_11929"
        x1="871.986"
        y1="62.6292"
        x2="871.986"
        y2="342.05"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E9ECEF" />
        <stop offset="1" stopColor="#BDBDBD" />
      </linearGradient>
    </defs>
  </svg>
);

export default VisualizationDecoration;
